import { Action } from "@ngrx/store";
import { LocalizedContent } from "../../commons/model/localized-content";

export enum ActionTypes {
  SetCurrentContent = "[Content Service] Current content updated",
  SetCurrentSection = "[Content Service] Current Section updated",
  SetCurrentGUID = "[Content Service] Current GUID updated"
}

export class SetCurrentContent implements Action {
  readonly type = ActionTypes.SetCurrentContent;

  constructor(public payload: { localizedContent: LocalizedContent }) {}
}

export class SetCurrentSection implements Action {
  readonly type = ActionTypes.SetCurrentSection;

  constructor(public payload: { section: string, sectionName: string }) {}
}

export class SetCurrentGUID implements Action {
  readonly type = ActionTypes.SetCurrentGUID;

  constructor(public payload: { guid: string }) {}
}

export type ActionsUnion = SetCurrentContent | SetCurrentSection | SetCurrentGUID;