import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ComponentRef, OverlayEventDetail, ModalOptions } from "@ionic/core";
import { from, Observable } from "rxjs";
import { flatMap, tap } from "rxjs/operators";
import { zoomInAnimation, zoomOutAnimation } from "../animations";
import { NgxUiLoaderService } from 'ngx-ui-loader';

// NON PUO' ESSERE PROVIDED IN ROOT PERCHE' ALTRIMENTI NON HA VISIBILITA' DEGLI ALTRI COMPONENTI - MRosswelt docet
@Injectable()
export class ModalService {
  constructor(private modalController: ModalController, private loaderService: NgxUiLoaderService) { }

  showModal(opts: ModalOptions<ComponentRef>, withLoader: boolean = false): Observable<OverlayEventDetail> {
    if (withLoader) this.loaderService.start();
    if (!opts.enterAnimation) opts.enterAnimation = zoomInAnimation;
    if (!opts.leaveAnimation) opts.leaveAnimation = zoomOutAnimation;
    return from(this.modalController.create(opts)).pipe(
      tap(modal => modal.present().then(() => { if (withLoader) this.loaderService.stop() })),
      flatMap(modal => {
        return from(modal.onDidDismiss());
      })
    );
  }

  dismissModal(params: any) {
    this.modalController.dismiss(params);
  }
}