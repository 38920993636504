import { ActionReducerMap, createSelector, MetaReducer, ActionReducer } from "@ngrx/store";
import { localStorageSync } from 'ngrx-store-localstorage';
import * as userSelectors from '../selectors/user.selector';
import * as fromUser from "./user.reducer";
import * as contentSelectors from '../selectors/content.selector';
import * as fromContent from "./content.reducer";

export interface AppState {
  userState: fromUser.UserState;
  contentState: fromContent.ContentState;
}

export const reducers: ActionReducerMap<AppState> = {
  userState: fromUser.reducer,
  contentState: fromContent.reducer
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: ['contentState', 'userState'], rehydrate: true})(reducer);
}
export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

export const getUsersState = (state: AppState) => state.userState;
export const getCurrentUser = createSelector(
  getUsersState,
  userSelectors.currentUser
);

export const getContentState = (state: AppState) => state.contentState;
export const getCurrentContent = createSelector(
  getContentState,
  contentSelectors.currentContent
);
export const getCurrentSection = createSelector(
  getContentState,
  contentSelectors.currentSection
);
export const getCurrentSectionName = createSelector(
  getContentState,
  contentSelectors.currentSectionName
);
export const getCurrentGUID = createSelector(
  getContentState,
  contentSelectors.currentGUID
);
