import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UserDTO } from "../../model/user";

export interface LoginSuccessDTO {
  access_token: string;
  expires_at: string;
  token_type: string;
  user: UserDTO;
}

@Injectable({
  providedIn: "root"
})
export class LaravelAuthService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private get ROUTES() {
    return {
      currentUser: environment["baseURL"]+ "/api/auth/user",
      login: environment["baseURL"] + "/api/auth/login",
      directLogin: environment["baseURL"] + "/api/auth/direct_login",
      show: environment["baseURL"] + "/api/user",
    };
  }

  public getCurrentUser(): Observable<UserDTO> {
    return this.httpClient.get<UserDTO>(this.ROUTES.currentUser);
  }

  public login(email: string, password: string): Observable<LoginSuccessDTO> {
    let params = {
      email: email,
      password: password
    };
    return this.httpClient.post<LoginSuccessDTO>(this.ROUTES.login, params);
  }

  public directLogin(queryString: string): Observable<LoginSuccessDTO> {
    return this.httpClient.get<LoginSuccessDTO>(`${this.ROUTES.directLogin}${queryString}`);
  }

  // http://localhost:8000/api/auth/direct_login?iduser=214664&username=ITADMIN19&dealercode=999997&userlevel=0&timestamp=1620914667858&hash=d306071e91c775df9ecfd93a0820e17e55cb5114

}
