import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { flatMap, map, catchError, delay } from "rxjs/operators";

import { StorageService } from "./storage.service";
import { AppState, getCurrentUser } from "../../store/reducers/index";
import { Store } from "@ngrx/store";

import * as UserActions from "../../store/actions/user.actions";
import { STORAGE_TOKEN } from 'src/app/helpers/storage.helper';
import { User } from '../model/user';
import { LaravelAuthService } from './backend/laravel-auth.service';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(
    private storageService: StorageService,
    private laravelAuthService: LaravelAuthService,
    private store: Store<AppState>
  ) {}

  loadCurrentUser(): Promise<User> {
    return this.laravelAuthService
      .getCurrentUser()
      .pipe(
        map(dto => {
          let user = new User(dto);
          this.setCurrentUser(user);
          return user;
        }),
        catchError(error => {
          if (error && error instanceof HttpErrorResponse) {
            if (error.status == 401) {
              return Promise.resolve(null);
            }
          }
          return Promise.reject(error);
        })
      )
      .toPromise();
  }

  login(email: string, password: string): Observable<User> {
    return this.laravelAuthService.login(email, password).pipe(
      flatMap(result => {
        return this.afterLogin(result.access_token).pipe(
          map(() => {
            let user: User = new User(result.user);
            this.store.dispatch(new UserActions.SignIn({ user: user }));
            return user;
          })
        );
      })
    );
  }

  directLogin(queryString: string) {
    return this.laravelAuthService.directLogin(queryString).pipe(
      flatMap(result => {
        return this.afterLogin(result.access_token).pipe(
          map(() => {
            let user: User = new User(result.user);
            this.store.dispatch(new UserActions.SignIn({ user: user }));
            return user;
          })
        );
      })
    );
  }

  logout(): Observable<any> {
    return from(this.storageService.remove(STORAGE_TOKEN)).pipe(
      map(() => {
        this.store.dispatch(new UserActions.SignOut());
      })
    );
  }

  isAuthenticated(): Observable<boolean> {
    return this.store.select(getCurrentUser).pipe(map(user => user != null));
  }

  private afterLogin(access_token: string): Observable<any> {
    return from(this.storageService.set(STORAGE_TOKEN, access_token));
  }

  private setCurrentUser(user: User) {
    this.store.dispatch(new UserActions.SetCurrentUser({ user: user }));
  }

}