import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-pwa-banner',
  templateUrl: './pwa-banner.component.html',
  styleUrls: ['./pwa-banner.component.scss'],
})
export class PwaBannerComponent implements OnInit {

  animationOptions = {
    loop: true,
    prerender: false,
    autoplay: false,
    autoloadSegments: false,
    path: 'assets/animations/ms__install.json'
  }

  anim: any;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  handleAnimation(anim) {
    this.anim = anim;
    this.anim.play();
  }

  dismiss() {
    this.modalController.dismiss();
  }
}