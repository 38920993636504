import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { ToastService } from "src/app/commons/services/toast.service";
import { FeedbackService } from "src/app/commons/services/frontend/feedback.service";
import { take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit {
  feedbackForm: FormGroup;
  sendAnim: any;

  animationOptions = {
    loop: false,
    prerender: true,
    autoplay: false,
    autoloadSegments: false,
    path: "assets/animations/ms__send.json",
  };

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private feedbackService: FeedbackService,
    private modalController: ModalController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this._createForm();
  }

  handleAnimation(anim) {
    this.sendAnim = anim;
  }

  _createForm() {
    this.feedbackForm = this.fb.group({
      feedback: ["", [Validators.required, Validators.maxLength(250)]],
      name: "",
      surname: "",
      dealer: "",
      city: "",
    });
  }

  dismiss() {
    this.modalController.dismiss();
  }

  send() {
    this.feedbackService
      .postFeedback(
        this.feedbackForm.value.feedback,
        this.feedbackForm.value.name,
        this.feedbackForm.value.surname,
        this.feedbackForm.value.dealer,
        this.feedbackForm.value.city
      )
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.toastService
            .showToast(
              `${this.translateService.instant("NOTIFY.feedbackSuccess")}`
            )
            .pipe(take(1))
            .subscribe();
        } else {
          this.toastService
            .showToast(
              `${this.translateService.instant("NOTIFY.feedbackError")}`
            )
            .pipe(take(1))
            .subscribe();
        }
        this.modalController.dismiss();
      });
  }
}
