import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { FeedbackDTO } from "../../model/feedback";

@Injectable({
  providedIn: "root",
})
export class LaravelFeedbackService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      list: environment["baseURL"] + "/api/feedbacks",
      create: environment["baseURL"] + "/api/feedbacks",
    };
  }

  index(): Observable<FeedbackDTO[]> {
    return this.httpClient.get<FeedbackDTO[]>(`${this.ROUTES.list}`);
  }

  listByContent(
    contentID: number,
    sectionName: string,
    language: string
  ): Observable<FeedbackDTO[]> {
    return this.httpClient.get<FeedbackDTO[]>(
      `${this.ROUTES.list}/${contentID}/${sectionName}/${language}`
    );
  }

  create(
    sectionName: string,
    feedback: string,
    name: string,
    surname: string,
    dealer: string,
    city: string,
    guid: string,
    contentID: number
  ): Observable<FeedbackDTO> {
    return this.httpClient.post<FeedbackDTO>(`${this.ROUTES.create}`, {
      section_name: sectionName,
      feedback: feedback,
      name: name,
      surname: surname,
      dealer: dealer,
      city: city,
      guid: guid,
      localized_content_id: contentID,
    });
  }

  edit(feedbackID: number, text: string): Observable<FeedbackDTO> {
    return this.httpClient.put<FeedbackDTO>(
      `${this.ROUTES.list}/${feedbackID}`,
      { feedback: text }
    );
  }

  delete(feedbackID: number): Observable<FeedbackDTO> {
    return this.httpClient.delete<FeedbackDTO>(
      `${this.ROUTES.list}/${feedbackID}`
    );
  }
}
