import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root"
})
export class PromptService {

    deferredPrompt: any;
  
    constructor() {} 
    
    setPrompt(prompt: any){
        this.deferredPrompt = prompt;
    }
  
    getPrompt(){
        return this.deferredPrompt;
    }
}