import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { from, Observable } from "rxjs";
import { flatMap, map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AlertService {
  constructor(private alertController: AlertController) {}

  showAlert(title: string, message: string, confirmLabel: string = "Ok"): Observable<boolean> {
    return this.doShow(title, message, confirmLabel, null);
  }

  showConfirm(title: string, message: string, confirmLabel: string = "Ok", cancelLabel: string = "Annulla"): Observable<boolean> {
    return this.doShow(title, message, confirmLabel, cancelLabel);
  }

  private doShow(title: string, message: string, confirmLabel: string, cancelLabel: string): Observable<boolean> {
    let buttons = [];
    if (confirmLabel) {
      buttons.push({ text: confirmLabel, role: "ok" });
    }
    if (cancelLabel) {
      buttons.push({ text: cancelLabel, role: "cancel" });
    }

    return from(
      this.alertController.create({
        header: title,
        message: message,
        buttons: buttons
      })
    ).pipe(
      tap(alert => alert.present()),
      flatMap(alert => {
        return from(alert.onDidDismiss()).pipe(map(result => result.role === "ok"));
      })
    );
  }
}