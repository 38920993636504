import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LottieAnimationViewModule } from 'ng-lottie';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

// COMPONENTS
import { PreloadImageComponent } from './components/preload-image/preload-image.component';
import { PwaBannerComponent } from './components/pwa-banner/pwa-banner.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { AnimatedStarComponent } from './components/animated-star/animated-star.component';
import { ScrollHideDirective } from './directives/scroll-hide.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AnimatedBookmarkComponent } from './components/animated-bookmark/animated-bookmark.component';
import { CoverComponent } from './components/cover/cover.component';
import { TableOfContentsComponent } from './components/table-of-contents/table-of-contents.component';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';

// MATERIAL TREEVIEW
import { MatTreeModule } from '@angular/material/tree';
@NgModule({
  declarations: [
    CoverComponent,
    TableOfContentsComponent,
    PwaBannerComponent,
    FeedbackComponent,
    AnimatedStarComponent,
    AnimatedBookmarkComponent,
    PreloadImageComponent,
    ScrollHideDirective,
    SafeHtmlPipe,
    HighlightSearchPipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    LottieAnimationViewModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatTreeModule
  ],
  exports: [
    CoverComponent,
    TableOfContentsComponent,
    PwaBannerComponent,
    FeedbackComponent,
    AnimatedStarComponent,
    AnimatedBookmarkComponent,
    PreloadImageComponent,
    ScrollHideDirective,
    SafeHtmlPipe,
    HighlightSearchPipe
  ],
  entryComponents: [
    PwaBannerComponent,
    FeedbackComponent,
    CoverComponent
  ]
})
export class SharedModule { }
