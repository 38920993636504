import { NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';

export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    blur: 10,
    overlayColor: "rgba(244, 244, 244, 0.5)",
    fgsColor: "#12243F",
    fgsPosition: "center-center",
    fgsSize: 200,
    fgsType: "three-strings",
    bgsColor: "#ffffff",
    bgsPosition: "center-center",
    bgsSize: 200,
    bgsType: "three-strings",
    gap: 24,
    logoPosition: "center-center",
    logoSize: 120,
    logoUrl: "../assets/images/ms__logo.svg",
    hasProgressBar: true,
    pbColor: "#12243F",
    pbDirection: "ltr",
    pbThickness: 5,
    threshold: 5,
};