import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BehaviorSubject, of } from 'rxjs';
import { ToastService } from 'src/app/commons/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ContentService } from 'src/app/commons/services/frontend/content.service';
import { catchError, take } from 'rxjs/operators';

@Component({
  selector: 'app-animated-star',
  templateUrl: './animated-star.component.html',
  styleUrls: ['./animated-star.component.scss'],
  animations: [
    trigger('star', [
      state('unliked', style({
        transform: 'rotate(0)'
      })),
      state('liked', style({
        transform: 'rotate(360deg)'
      })),
      transition('unliked <=> liked', [
        animate('.3s ease-out')
      ]),
    ])
  ]
})
export class AnimatedStarComponent implements OnInit {
  
  @Input('locContentID') contentID: number;
  @Input('isButton') isButton: boolean = false;
  public likeState: BehaviorSubject<string> = new BehaviorSubject<string>('unliked');

  constructor(
    private contentService: ContentService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  ngOnInit() { }

  toggleLikeState(){
    if (this.likeState.value == 'unliked') {
      this.addToFavs();
    } else {
      this.removeFromFavs();
    }
  }

  private addToFavs() {
    this.contentService.addToFavorites(this.contentID).subscribe((locContent) => {
      if (locContent) {
        this.likeState.next('liked');
        this.toastService.showToast(`${this.translateService.instant('NOTIFY.favAdd')}`).pipe(take(1)).subscribe();
      } else {
        this.likeState.next('unliked');
        this.toastService.showToast(`${this.translateService.instant('NOTIFY.favoriteError')}`).pipe(take(1)).subscribe();
      }
    });
  }

  private removeFromFavs() {
    this.contentService.removeFromFavorites(this.contentID).subscribe((locContent) => {
      if (locContent) {
        this.likeState.next('unliked');
        this.toastService.showToast(`${this.translateService.instant('NOTIFY.favRemove')}`).pipe(take(1)).subscribe();
      } else {
        this.likeState.next('liked');
      }
    });
  }

  @Input()
  public set fav(fav: boolean) {
    this.likeState.next(fav ? 'liked' : 'unliked');
  }

  public get fav(): boolean {
    return this.likeState.value == 'liked';
  }

  public get iconName(): string {
    return this.fav ? 'star' : 'star-outline';
  }

}
