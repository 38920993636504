import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { STORAGE_LANG } from 'src/app/helpers/storage.helper';
import { BehaviorSubject } from 'rxjs';

export interface Language {
  text: string,
  value: string,
  backendValue: string,
  img: string
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  selectedLanguage$: BehaviorSubject<Language> = new BehaviorSubject<Language>(null);
 
  constructor(
    private translate: TranslateService, 
    private storage: StorageService
  ) { }
 
  async setInitialAppLanguage() {
    let language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
 
    let storageLanguage = await this.storage.get(STORAGE_LANG);
    if (storageLanguage) {
      this.selectedLanguage$.next(JSON.parse(storageLanguage));
      this.setLanguage();
    } else {
      let found = this.getLanguages().find(l => l.value == language);
      if(!found) {
        //Fallback su inglese
        found = this.getLanguages().find(l => l.value == 'en');
      }
      this.selectedLanguage$.next(found);
    }
  }
 
  getLanguages(): Language[] {
    return [
      { text: 'English', value: 'en', backendValue: 'en-gb', img: 'assets/images/flags/en.png' },
      { text: 'Italiano', value: 'it', backendValue: 'it', img: 'assets/images/flags/it.png' },
      { text: 'Español', value: 'es', backendValue: 'es', img: 'assets/images/flags/es.png' },
      { text: 'Deutsche', value: 'de', backendValue: 'de', img: 'assets/images/flags/de.png' },
      { text: 'Français', value: 'fr', backendValue: 'fr', img: 'assets/images/flags/fr.png' },
      { text: 'Japanese', value: 'ja', backendValue: 'ja', img: 'assets/images/flags/ja.png' },
      { text: 'Korean', value: 'ko', backendValue: 'ko', img: 'assets/images/flags/kr.png' },
      { text: 'Turkish', value: 'tr', backendValue: 'tr', img: 'assets/images/flags/tr.png' },
      { text: 'Chinese', value: 'zh', backendValue: 'zh-cn', img: 'assets/images/flags/zh.png' },
    ];
  }
 
  setLanguage(lng?: Language) {
    if (lng) {
      this.translate.use(lng.value);
      this.selectedLanguage$.next(lng);
      this.storage.set(STORAGE_LANG, JSON.stringify(lng));
    } else {
      this.translate.use(this.selectedLanguage.value);
    }
  }

  get selectedLanguage(): Language {
    return this.selectedLanguage$.getValue();
  }
}