import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./commons/guards/auth.guard";
import { AlreadyLoggedGuard } from "./commons/guards/already-logged.guard";
import { HomeGuard } from "./commons/guards/home.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: "./modules/home/home.module#HomePageModule",
    canActivate: [HomeGuard], //HomeGuard
  },
  {
    path: "welcome",
    loadChildren: "./modules/welcome-page/welcome-page.module#WelcomePageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    loadChildren: "./modules/login/login.module#LoginPageModule",
    canActivate: [AlreadyLoggedGuard],
  },
  { 
    path: 'direct', 
    loadChildren: './modules/direct-login/direct-login.module#DirectLoginPageModule',
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      enableTracing: false,
      onSameUrlNavigation: "reload"
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
