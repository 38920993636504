import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from 'src/app/commons/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-animated-bookmark',
  templateUrl: './animated-bookmark.component.html',
  styleUrls: ['./animated-bookmark.component.scss'],
  animations: [
    trigger('bookmark', [
      state('unliked', style({
        transform: 'translate(-50%, -50%) scale(1)'
      })),
      state('liked', style({
        transform: 'translate(-50%, -50%) scale(1.15)'
      })),
      transition('unliked <=> liked', [
        animate('.3s ease-out')
      ]),
    ])
  ]
})
export class AnimatedBookmarkComponent implements OnInit {

  public likeState: BehaviorSubject<string> = new BehaviorSubject<string>('unliked');

  constructor(
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  ngOnInit() { }

  toggleLikeState(){
    if (this.likeState.value == 'unliked') {
      this.addToFavs();
    } else {
      this.removeFromFavs();
    }
  }

  private addToFavs() {
    this.likeState.next('liked');
    // TODO: logica add to bookmarks
    this.toastService.showToast(`${this.translateService.instant('NOTIFY.bookmarkAdd')}`).subscribe();
  }

  private removeFromFavs() {
    this.likeState.next('unliked');
    // TODO: logica remove from bookmarks
    this.toastService.showToast(`${this.translateService.instant('NOTIFY.bookmarkRemove')}`).subscribe();
  }

  @Input()
  public set bookmarked(fav: boolean) {
    this.likeState.next(fav ? 'liked' : 'unliked');
  }

  public get bookmarked(): boolean {
    return this.likeState.value == 'liked';
  }

  public get iconName(): string {
    return this.bookmarked ? 'bookmark' : 'bookmark-outline';
  }

}
