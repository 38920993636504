
import * as fromUser from "../actions/user.actions";
import { User } from 'src/app/commons/model/user';

export interface UserState {
  currentUser: User;
}

export const initialState: UserState = {
  currentUser: null
};

export function clearState(reducer) {
  return function(state, action) {
    if (action.type === fromUser.ActionTypes.SignOut) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export function reducer(
  state = initialState,
  action: fromUser.ActionsUnion
): UserState {
  switch (action.type) {
    case fromUser.ActionTypes.SignIn:
    case fromUser.ActionTypes.SetCurrentUser: {
      return {
        ...state,
        currentUser: action.payload.user
      };
    }
    case fromUser.ActionTypes.SignOut: {
      return {
        ...state,
        currentUser: null
      };
    }

    default: {
      return state;
    }
  }
}