import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { DomController } from '@ionic/angular';

const ANIMATIONS_TRIGGER_DISTANCE = 25;

@Directive({
  selector: '[scrollHide]'
})
export class ScrollHideDirective {

  @Input('scrollHide') scrollArea;

  private isHidden: boolean = false;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private domCtrl: DomController,
  ) { }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.scrollArea.ionScroll.subscribe((scrollEvent: CustomEvent) => {
      if ((scrollEvent.detail.deltaY > ANIMATIONS_TRIGGER_DISTANCE) && !this.isHidden) {
        this.hide();
      } else if ((scrollEvent.detail.deltaY < - (ANIMATIONS_TRIGGER_DISTANCE)) && this.isHidden) {
        this.show();
      }
    });
  }

  private hide() {
    this.domCtrl.write(() => {
      this.renderer.addClass(this.element.nativeElement, 'collapsed');
    });

    this.isHidden = true;
  }

  private show() {
    this.domCtrl.write(() => {
      this.renderer.removeClass(this.element.nativeElement, 'collapsed');
    });

    this.isHidden = false;
  }
}
