import { BaseDTO, Base } from './base';
import { Content, ContentDTO } from './content';

export interface LocalizedContentDTO extends BaseDTO {
    content_id?: number;
    content?: ContentDTO;
    name: string;
    localized_name: string;
    filename: string;
    language: string;
    level: number;
    guid: string;
    pdf_filename_size: string;
    pdf_filename_url: string;
    parent_id?: number;
    parent?: LocalizedContentDTO;
    children?: LocalizedContentDTO[];
    all_children?: LocalizedContentDTO[];
    title: string;
    subtitle: string;
}

export class LocalizedContent extends Base {

    contentID?: number;
    content?: Content;
    name: string;
    localizedName: string;
    filename: string;
    language: string;
    level: number;
    guid: string;
    
    pdfSize: string;
    pdfURL: string;
    parentID?: number;
    parent?: LocalizedContent;
    children?: LocalizedContent[];
    allChildren?: LocalizedContent[];

    title: string;
    subTitle: string;

    constructor(source: LocalizedContentDTO) {
        super(source);
        if (source) {
            this.contentID = source.content_id;
            this.content = source.content ? new Content(source.content) : null;
            this.name = source.name;
            this.localizedName = source.localized_name;
            this.filename = source.filename;
            this.language = source.language;
            this.level = source.level;
            this.guid = source.guid;
            this.pdfSize = source.pdf_filename_size;
            this.pdfURL = source.pdf_filename_url;
            this.parentID = source.parent_id;
            this.parent = source.parent ? new LocalizedContent(source.parent) : null;
            this.children = source.children ? source.children.map((child) => new LocalizedContent(child)) : null;
            this.allChildren = source.all_children ? source.all_children.map((child) => new LocalizedContent(child)) : null;
            this.title = source.title;
            this.subTitle = source.subtitle;
        }
    }

    public static deserialize(source: any) {
        return Object.assign(new LocalizedContent(null), source);
    }

    public toDTO(): LocalizedContentDTO {
        let result: LocalizedContentDTO = <LocalizedContentDTO>super.toDTO();
        Object.assign(result, {
            content_id: this.contentID,
            content: this.content.toDTO(),
            name: this.name,
            localized_name: this.localizedName,
            filename: this.filename,
            language: this.language,
            level: this.level,
            guid: this.guid,
            pdf_filename_size: this.pdfSize,
            pdf_filename_url: this.pdfURL,
            parent_id: this.parentID,
            parent: this.parent.toDTO(),
            children: this.children.map((child) => child.toDTO()),
            all_children: this.allChildren.map((child) => child.toDTO()),
            title: this.title,
            subtitle: this.subTitle
        });

        return result;
    }

    get coverTitle(): string {
        return this.title ? this.title : this.name;
    }

    get displayName(): string {
        return this.localizedName ? this.localizedName : this.name;
    }

    public get localizedContentTitle(): string {
        return this.content.localizedContents.find(
            l => l.language==this.language
          ).title;
    }
}