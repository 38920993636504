import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { STORAGE_WELCOME } from "src/app/helpers/storage.helper";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor(private storage: Storage, private platform: Platform) {}

  public set(key: string, value: any): Promise<any> {
    return this.isMobileApp
      ? this.storage.set(key, value)
      : Promise.resolve(localStorage.setItem(key, value));
  }

  public get(key: string): Promise<any> {
    return this.isMobileApp
      ? this.storage.get(key)
      : Promise.resolve(localStorage.getItem(key));
  }

  public remove(key: string): Promise<any> {
    return this.isMobileApp
      ? this.storage.remove(key)
      : Promise.resolve(localStorage.removeItem(key));
  }

  private get isMobileApp() {
    return this.platform.is("cordova");
  }

}
