import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';

import { AuthService } from './commons/services/auth.service';
import { LanguageService } from './commons/services/language.service';
import { ConnectionStatus, NetworkService } from './commons/services/network.service';
import { PlatformService } from './commons/services/platform.service';
import { PromptService } from './commons/services/prompt.service';
import { ToastService } from './commons/services/toast.service';
import { AppState, getCurrentUser } from './store/reducers';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  
  routingDestination: string;

  constructor(
    private platformService: PlatformService,
    private router: Router,
    private networkService: NetworkService,
    private toastService: ToastService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private promptService: PromptService,
    private authService: AuthService,
    private store: Store<AppState>
  ) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationStart)
    ).subscribe((e: NavigationStart) => this.routingDestination = e.url)

    this._handleSplashScreen();
    this.initializeApp();
  }

  initializeApp() {
    this.platformService.isReady().then(() => {

      // SELECT LANGUAGE
      this.languageService.setInitialAppLanguage();

      if (!this.routingDestination.startsWith("/direct")) {

        this.authService.loadCurrentUser();

        // CHECK USER
        // this._watchUser();
      }

      // CHECK NETWORK
      this._watchConnectionStatus();

      // DEFER PROMPT
      this._deferPromptBanner();
    });
  }

  private _deferPromptBanner() {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later on the button event.
      console.log(e);

      this.promptService.setPrompt(e);
    });
  }

  private async _handleSplashScreen(): Promise<void> {
    try {
      // wait for App to finish loading
      await this.platformService.isReady();
    } catch (error) {
      console.error("Platform initialization bug");
    }

    // Any operation that shoud be performed BEFORE showing user UI,
    // in a real App that may be cookie based authentication check e.g.
    // await this.authProvider.authenticate(...)

    // fade out and remove the #splash-screen

    const splash = document.getElementById("splash-screen");
    splash.style.opacity = "0";
    setTimeout(() => {
      splash.remove();
    }, 300);

    // if (!this.platformService.isNativeDevice() && this._checkPWA()) {
    //   await this._presentBanner();
    // }
  }

  private _watchUser() {
    this.store.select(getCurrentUser).subscribe((user) => {
      if (!user) {
        this.router.navigate(["/login"]);
      }
    });
  }

  private _watchConnectionStatus() {
    this.networkService
      .onNetworkChange()
      .subscribe((status: ConnectionStatus) => {
        if (status == ConnectionStatus.Offline) {
          this.toastService
            .showToast(this.translateService.instant("NOTIFY.offline"))
            .pipe(take(1))
            .subscribe();

          // TODO: Non si sa se sia la logica giusta per loro... Issue: #13 Github
          // this.router.navigate(["offline"]);
        } else {
          console.log('check connection')
          // this.router.navigate([""]);
        }
      });
  }
}
