import { BaseDTO, Base } from './base';

export interface UserDTO extends BaseDTO {
    name: string;
    last_name: string;
    email: string;
    email_verified_at: number;
    asset_token: string;
}

export class User extends Base {
    name: string;
    lastName: string;
    email: string;
    emailVerifiedAt: number;
    assetToken: string;

    constructor(source: UserDTO) {
        super(source);
        if (source) {
            this.name = source.name;
            this.lastName = source.last_name;
            this.email = source.email;
            this.emailVerifiedAt = source.email_verified_at;
            this.assetToken = source.asset_token;
        }
    }   

    public toDTO(): UserDTO {
        let result: UserDTO = <UserDTO>super.toDTO();
        Object.assign(result, {
            name: this.name,
            last_name: this.lastName,
            email: this.email,
            email_verified_at: this.emailVerifiedAt,
            asset_token: this.assetToken
        });

        return result;
    }

    get fullName(): string {
        return `${this.name} ${this.lastName}`;
    }
}
  