
export interface BaseDTO {
  id: number;
  created_at?: string;
  updated_at?: string;
}
  
export class Base {

  id: number;
  createdAt: Date;
  updatetAt: Date;

  constructor(source: BaseDTO) {
    if (source) {
      this.id = source.id;
      this.createdAt = source.created_at ? new Date(source.created_at) : null;
      this.updatetAt = source.updated_at ? new Date(source.updated_at) : null;
    }
  }

  public toDTO(): BaseDTO {
    return {
      id: this.id
    };
  }
}