import { AuthService } from "../auth.service";
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { StorageService } from "../storage.service";
import { Observable, from } from "rxjs";
import { mergeMap, catchError, map, take } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { STORAGE_TOKEN } from 'src/app/helpers/storage.helper';

@Injectable({
  providedIn: "root"
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private storageService: StorageService, private authService: AuthService, private router: Router, 
    private route: ActivatedRoute
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.storageService.get(STORAGE_TOKEN)).pipe(
      mergeMap(token => {
        let clonedReq = this.addToken(req, token);
        return next.handle(clonedReq).pipe(
          map(resp => {
            // on Response
            if (resp instanceof HttpResponse) {
              // Do whatever you want with the response.
              return resp;
            }
          }),
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              if (err.status == 401) {
                this.authService.logout().pipe(take(1)).subscribe(() => {
                  this.router.navigate(["/login"]);
                });
              }
            }
            throw err;
          })
        );
      })
    );
  }

  /**
   * Adds the token to your headers if it exists
   * @param request
   * @param token
   */
  private addToken(request: HttpRequest<any>, token: any) {
    if (token) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        setHeaders: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      return clone;
    }
    return request;
  }

}