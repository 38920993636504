import { Action } from "@ngrx/store";
import { User } from 'src/app/commons/model/user';

export enum ActionTypes {
  SetCurrentUser = "[Auth Service] Current user updated",
  SignIn = "[Auth Service] User logged in",
  SignOut = "[Auth Service] User logged out"
}

export class SignIn implements Action {
  readonly type = ActionTypes.SignIn;

  constructor(public payload: { user: User }) {}
}

export class SetCurrentUser implements Action {
  readonly type = ActionTypes.SetCurrentUser;

  constructor(public payload: { user: User }) {}
}

export class SignOut implements Action {
  readonly type = ActionTypes.SignOut;

  constructor() {}
}

export type ActionsUnion = SignIn | SetCurrentUser | SignOut;