import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { STORAGE_WELCOME } from "src/app/helpers/storage.helper";

import { StorageService } from "../services/storage.service";

@Injectable({
  providedIn: "root",
})
export class HomeGuard implements CanActivate {
  constructor(private router: Router, private storageService: StorageService) {}

  canActivate(): Promise<boolean> {
    return this.storageService.get(STORAGE_WELCOME).then((res) => {
      if (!res) {
        this.router.navigate(["welcome"]);
        return false;
      }
      return true;
    });
  }
}
