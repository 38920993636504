import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { from, Observable } from "rxjs";
import { flatMap, tap, map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  showToast(
    message: string,
    closeText: string = null,
    duration: number = 2500,
    position: "top" | "bottom" | "middle" = "top"
  ): Observable<boolean> {
    return from(
      this.toastController.create({
        message: message,
        showCloseButton: closeText != null,
        closeButtonText: closeText,
        position: position,
        duration: duration
      })
    ).pipe(
      tap(toast => toast.present()),
      flatMap(toast => {
        return from(toast.onDidDismiss()).pipe(
          map(result => result.role != "timeout")
        );
      })
    );
  }

  showError(message: string, position: "top" | "bottom" | "middle" = "middle") {
    this.toastController
      .create({
        message: message,
        showCloseButton: false,
        duration: 5000,
        position: position,
        cssClass: "error"
      })
      .then(toast => {
        toast.present();
      });
  }
}