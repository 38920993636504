import { BaseDTO, Base } from "./base";
import { UserDTO, User } from "./user";
import { LocalizedContentDTO, LocalizedContent } from "./localized-content";

export interface FeedbackDTO extends BaseDTO {
  guid: string;
  feedback: string;
  localized_content_id?: number;
  localized_content?: LocalizedContentDTO;
  user_id?: number;
  user?: UserDTO;
  name?: string;
  surname?: string;
  dealer?: string;
  city?: string;
}

export class Feedback extends Base {
  guid: string;
  text: string;
  localizedContentID: number;
  localizedContent: LocalizedContent;
  userID: number;
  user: User;
  name: string;
  surname: string;
  dealer: string;
  city: string;

  constructor(source: FeedbackDTO) {
    super(source);
    if (source) {
      this.guid = source.guid;
      this.text = source.feedback;
      this.userID = source.user_id;
      this.user = source.user ? new User(source.user) : null;
      this.localizedContentID = source.localized_content_id;
      this.localizedContent = source.localized_content
        ? new LocalizedContent(source.localized_content)
        : null;
      this.name = source.name;
      this.surname = source.surname;
      this.dealer = source.dealer;
      this.city = source.city;
    }
  }

  public toDTO(): FeedbackDTO {
    let result: FeedbackDTO = <FeedbackDTO>super.toDTO();
    Object.assign(result, {
      guid: this.guid,
      feedback: this.text,
      user_id: this.userID,
      user: this.user ? this.user.toDTO() : null,
      localized_content_id: this.localizedContentID,
      localized_content: this.localizedContent
        ? this.localizedContent.toDTO()
        : null,
      name: this.name,
      surname: this.surname,
      dealer: this.dealer,
      city: this.city,
    });

    return result;
  }

  public get localizedContentTitle(): string {
    return this.localizedContent.content.localizedContents.find(
      (l) => l.language == this.localizedContent.language
    ).title;
  }
}
