import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { PlatformService } from './platform.service';
 
export enum ConnectionStatus {
  Online,
  Offline
}
 
@Injectable({
  providedIn: 'root'
})
export class NetworkService {
 
  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline);

  disconnectSubscription: Subscription;
  connectSubscription: Subscription;
 
  constructor(private platformService: PlatformService) {
    this.platformService.isReady().then(() => {
      this.status.next(ConnectionStatus.Online);
      this.initializeNetworkEvents();
    });
  }
 
  public initializeNetworkEvents() {
    if (!this.platformService.isNativeDevice()) {
      window.addEventListener('online',  () => { this.updateNetworkStatus(ConnectionStatus.Online) });
      window.addEventListener('offline',  () => { this.updateNetworkStatus(ConnectionStatus.Offline) });
    }
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }
 
  private async updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

    // TODO: remove event listeners
  }
}