import { BaseDTO, Base } from './base';
import { LocalizedContentDTO, LocalizedContent } from './localized-content';

export interface ContentDTO extends BaseDTO {
    name: string;
    image_url: string;
    languages?: string[];
    is_favorites_of_users_count: boolean;
    localized_contents?: LocalizedContentDTO[];
}

export class Content extends Base {

    name: string;
    imageURL: string;
    availableLanguages: string[];
    isFavorite: boolean;
    localizedContents?: LocalizedContent[];
    
    constructor(source: ContentDTO) {
        super(source);
        this.name = source.name;
        this.imageURL = source.image_url;
        this.availableLanguages = source.languages ? source.languages.map(lang => lang.toLowerCase()) : null;
        this.isFavorite = source.is_favorites_of_users_count;
        this.localizedContents = source.localized_contents ? source.localized_contents.map(localizedContentDTO => {return new LocalizedContent(localizedContentDTO);}) : null;
    }


    public toDTO(): ContentDTO {
        let result: ContentDTO = <ContentDTO>super.toDTO();
        Object.assign(result, {
            name: this.name,
            image_url: this.imageURL,
            languages: this.availableLanguages,
            is_favorites_of_users_count: this.isFavorite
        });

        return result;
    }
}